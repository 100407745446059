import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React, { useEffect, useMemo } from 'react';

import { Alert, Box, Checkbox, EntryList, Loader, Text } from '~/components/blocks';
import { AppointmentDeliveryMethod, Patient } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { getPfTel } from '~/utils/pf_contact_point';

import { Fields } from './types';
import { useFetchUberQuoteFee } from './use_fetch_uber_quote_fee';

type Props = {
  deliveryMethod: AppointmentDeliveryMethod;
  formik: FormikProps<Fields>;
  patient: Patient;
  availableSameDayDelivery: boolean;
  isPending: boolean;
  isOnline: boolean;
  fee: number;
  appointmentId: string;
};

const Root = styled('div')(({ theme }) =>
  css({
    borderRadius: theme.radii.default,
    background: theme.colors.background.bg,
    marginTop: theme.space.l,
    padding: theme.space.m,
  }),
);

const ParcelContent = styled(Box)(({ theme }) =>
  css({
    padding: theme.space.m,
    backgroundColor: 'white',
    borderRadius: theme.radii.default,
  }),
);

export const ParcelBox = React.memo((props: Props) => {
  const theme = useTheme();
  const {
    appointmentId,
    deliveryMethod,
    availableSameDayDelivery,
    isPending,
    isOnline,
    fee,
    formik,
    patient,
  } = props;
  const { enable: uberOrgSettingEnable, loading } = useUberOrganizationSetting();
  const {
    getQuote,
    loading: loadingQuote,
    uberQuoteDeliveryFee,
    uberQuoteDeliveryErrorCode,
  } = useFetchUberQuoteFee();

  const estimated_uber_delivery_fee = React.useMemo(() => {
    return uberQuoteDeliveryFee ?? fee;
  }, [fee, uberQuoteDeliveryFee]);

  const tel = getPfTel(patient.pfPatient?.telecoms || []) ?? '未登録';
  const address = patient.pfPatient?.addresses?.[0];
  const addressName = useMemo(() => {
    if (!address) {
      return '未登録';
    }
    return `〒${address.postalCode} ${address.prefecture}${address.city}${address.line}`;
  }, [address]);

  useEffect(() => {
    if (isOnline && availableSameDayDelivery) {
      getQuote({ variables: { id: appointmentId } });
    }
  }, [appointmentId, availableSameDayDelivery, getQuote, isOnline]);

  return (
    <Root>
      {loading || loadingQuote ? (
        <Loader open inside />
      ) : (
        <>
          <Checkbox
            label="当日配達を利用"
            name="isSameDayDelivery"
            checked={
              formik.values.isSameDayDelivery && availableSameDayDelivery && uberOrgSettingEnable
            }
            onChange={formik.handleChange}
            disabled={!availableSameDayDelivery || !uberOrgSettingEnable}
          />
          {!uberOrgSettingEnable && (
            <Alert status="error" marginY={theme.space.m}>
              {deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery ? (
                <Text fontWeight="bold">
                  設定が無効になっているため、当日配達を利用できません。患者にご相談の上、受け渡し方法を変更してください
                </Text>
              ) : (
                <Text>設定が無効になっているため、当日配達を利用できません。</Text>
              )}
            </Alert>
          )}
          {uberOrgSettingEnable && (!availableSameDayDelivery || uberQuoteDeliveryErrorCode) && (
            <Alert status="warning" marginY={theme.space.m}>
              {deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery ? (
                <Text fontWeight="bold">
                  薬局から患者指定住所まで距離が離れているため、当日配達を利用できません。患者にご相談の上、受け渡し方法を変更してください
                </Text>
              ) : (
                <Text>薬局から患者指定住所まで距離が離れているため、当日配達を利用できません</Text>
              )}
            </Alert>
          )}
          {isPending && (
            <Alert status="warning" marginY={theme.space.m}>
              <Text fontWeight="bold">
                「当日配達を利用」をオフにすると、当日配達および集荷依頼をキャンセルします。
              </Text>
            </Alert>
          )}
          {availableSameDayDelivery && formik.values.isSameDayDelivery && !isPending && (
            <Alert status="info" marginY={theme.space.m}>
              誤配送を防ぐため、当日配達の場合、お薬を受け取る際に署名が必要になります。患者には配達員の到着までご在宅いただくようお伝えください
            </Alert>
          )}
          <ParcelContent>
            <EntryList size="s">
              <EntryList.Head>住所</EntryList.Head>
              <EntryList.Body>{addressName}</EntryList.Body>
            </EntryList>
            <EntryList size="s">
              <EntryList.Head>電話番号</EntryList.Head>
              <EntryList.Body>{tel}</EntryList.Body>
            </EntryList>
            {availableSameDayDelivery && formik.values.isSameDayDelivery && (
              <EntryList size="s">
                <EntryList.Head>当日配達利用料</EntryList.Head>
                <EntryList.Body>
                  {estimated_uber_delivery_fee}〜{estimated_uber_delivery_fee + 100}円
                </EntryList.Body>
                <Text size="xs">
                  ※走行距離をもとに算出した見積額です。集荷依頼後、配達開始したタイミングで確定します。目安として患者にお伝えください
                </Text>
              </EntryList>
            )}
          </ParcelContent>
          <Box marginTop={theme.space.m}>
            <Text color="pink">
              上記住所・電話番号にお薬が配達されます。登録情報が古い場合は、患者にCLINICSアプリ上で更新をお願いしてください。
            </Text>
          </Box>
        </>
      )}
    </Root>
  );
});

ParcelBox.displayName = 'ParcelBox';
